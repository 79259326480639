<template>
  <div>
    <div :class="isPc ? 'pc-containers p-t-24' : 'mb-containers'">
      <div :class="isPc ? 'pc-contents' : 'mb-contents bank'">
        <v-card-text v-if="!isPc">
          <div class="bold-f-s">You're halfway there!</div>
        </v-card-text>
        <v-card-text :class="isPc ? 'text-center' : ''">
          <p>Let‘s move on to banking. The next step uses <span class="bold-f">{{ provider.name }}</span>,</p> our trusted provider, for
          an instant bank
          verification.
        </v-card-text>
        <v-card-text class="text-center">
          <a href="javascript:;" @click="decisionLogic.show = true">
            What is {{ provider.name }}?
          </a>
        </v-card-text>
      </div>
    </div>
    <v-card-actions :class="isPc ? 'pc-pages-action' : 'mb-pages-action m-0'">
      <v-btn
          large
          color="primary"
          :width="isPc ? '240' : '96vw'"
          class="m-0 f-s-18"
          :disabled="disabled"
          @click="next">
        Next
      </v-btn>
    </v-card-actions>
    <v-dialog
        persistent
        max-width="480"
        v-model="decisionLogic.show">
      <decision-logic :data="decisionLogic" :provider="provider"></decision-logic>
    </v-dialog>
  </div>
</template>
<script>
import { DataType, Tool } from '@/util/index.js'
import { OriginationApi } from '../../api/application'
import DecisionLogic from '@/components/ailurus/dialog/DecisionLogic'
const tool = new Tool()

export default {
  props: {
    isPc: {
      type: Boolean,
      default: true
    }
  },
  components: { DecisionLogic },
  data () {
    return {
      page: DataType.PAGES.DL_IF,
      decisionLogic: { show: false },
      loanId: '',
      provider: {
        name: '',
        code: null
      },
      disabled: true
    }
  },
  created () {
    const _this = this
    _this.loanId = localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID)
    _this.$store.commit('setCurrentPage', this.page)
    _this.getProvider()
  },
  methods: {
    next () {
      const $this = this
      OriginationApi.getLoanById({ loanId: this.loanId, feature: 'STATUS,PAYMENT_OPTION' }, function (result) {
        if (tool.isNotEmpty(result) && result.loanStatus !== DataType.LoanStatus.CUSTOMER_REVIEW.value) {
          $this.$router.push('/instant/notification/-100')
        } else {
          $this.$router.push(DataType.PAGES[$this.page.next].addressPC)
          $this.$eventBus.$emit('closeCountDown')
        }
      })
    },
    getProvider () {
      const $this = this
      OriginationApi.getProvider({ portfolioId: localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID) }, function (result) {
        if (tool.isNotEmpty(result)) {
          const providerEntity = result.providerEntity
          if (providerEntity === DataType.IBVProviderType.CHIRP.value) {
            $this.provider = {
              name: DataType.IBVProviderType.CHIRP.text,
              code: DataType.IBVProviderType.CHIRP.value
            }
          } else if (providerEntity === DataType.IBVProviderType.DECISION_LOGIC.value) {
            $this.provider = {
              name: DataType.IBVProviderType.DECISION_LOGIC.text,
              code: DataType.IBVProviderType.DECISION_LOGIC.value
            }
          }
          localStorage.setItem(DataType.COOKIE_KEY.PROVIDER, JSON.stringify($this.provider))
          $this.disabled = false
        }
      }, function () {
        $this.$router.push('/instant/notification/-100')
      })
    }
  }
}
</script>>
<style lang="css">
.bank input {
  text-align: left !important;
  font-size: 16px !important;
}
</style>
